<template>
  <vx-card class="documents-type-page main-box md:w-1/2 top-zero-radius p-2">
    <crud-tree v-for="(item, item_index) in tree"
               :key="item_index"
               :tree="item"
               :options="treeOptions"
               :hide-options="true"
               :first="true"
               :first-hide="true"/>
  </vx-card>
</template>

<script>
import {getAccounts} from '@/http/requests/accountancy/accounts'

export default {
  name: 'documentsType',
  metaInfo () {
    return {
      title: this.$t('accountancy.types.title')
    }
  },
  data () {
    return {
      treeOptions: {
        type: 'documentsType'
      },
      tree: []
    }
  },
  created () {
    this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')

    this.getAccounts()
  },
  methods: {
    getAccounts () {
      getAccounts().then(response => {
        const accounts = response.data.data, accounts_tree = []
        accounts.forEach((type) => {
          accounts_tree.push(this.getTree(type, true))
        })
        this.tree = accounts_tree
      })
    },
    getTree (type, first = false) {
      const data = {
        parent: {
          id: type.id,
          title: type.slug ? this.$t(`accountancy.types.accountsLabel.${type.slug}`) : type.name,
          code: type.code,
          type: type.type,
          balance: type.balance * -1 || '',
          lock: type.lock,
          parent_id: type.parent_id
        },
        children: [],
        options: {
          firstHide: !first,
          insertEnable: type.lock <= 1,
          updateEnable: type.lock === 0,
          deleteEnable: type.lock === 0
        }
      }

      if (type.children && type.children.length > 0) {
        type.children.forEach((child) => {
          data.children.push(this.getTree(child))
        })
      }

      return data
    }
  },
  watch: {
    '$store.state.helper.accountsChanged': {
      handler (val) {
        if (val) {
          this.getAccounts()
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
